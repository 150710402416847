/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "@circlon/angular-tree-component/css/angular-tree-component.css";
@import "firebaseui/dist/firebaseui.css";
// @import "codemirror/lib/codemirror";
// @import "codemirror/theme/material";
// code mirror

/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/

.cm-s-material.CodeMirror {
  background-color: #263238;
  color: #eeffff;
}

.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: #546e7a;
  border: none;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: #546e7a;
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #ffcc00;
}
.cm-s-material.cm-fat-cursor .CodeMirror-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-material .cm-animate-fat-cursor {
  background-color: #5d6d5c80 !important;
}

.cm-s-material div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line > span::selection,
.cm-s-material .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line > span::-moz-selection,
.cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-material .cm-keyword {
  color: #c792ea;
}

.cm-s-material .cm-operator {
  color: #89ddff;
}

.cm-s-material .cm-variable-2 {
  color: #eeffff;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #f07178;
}

.cm-s-material .cm-builtin {
  color: #ffcb6b;
}

.cm-s-material .cm-atom {
  color: #f78c6c;
}

.cm-s-material .cm-number {
  color: #ff5370;
}

.cm-s-material .cm-def {
  color: #82aaff;
}

.cm-s-material .cm-string {
  color: #c3e88d;
}

.cm-s-material .cm-string-2 {
  color: #f07178;
}

.cm-s-material .cm-comment {
  color: #546e7a;
}

.cm-s-material .cm-variable {
  color: #f07178;
}

.cm-s-material .cm-tag {
  color: #ff5370;
}

.cm-s-material .cm-meta {
  color: #ffcb6b;
}

.cm-s-material .cm-attribute {
  color: #c792ea;
}

.cm-s-material .cm-property {
  color: #c792ea;
}

.cm-s-material .cm-qualifier {
  color: #decb6b;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #decb6b;
}

.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

// code mirror material

/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/

.cm-s-material.CodeMirror {
  background-color: #263238;
  color: #eeffff;
}

.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: #546e7a;
  border: none;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: #546e7a;
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #ffcc00;
}
.cm-s-material.cm-fat-cursor .CodeMirror-cursor {
  background-color: #5d6d5c80 !important;
}
.cm-s-material .cm-animate-fat-cursor {
  background-color: #5d6d5c80 !important;
}

.cm-s-material div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line > span::selection,
.cm-s-material .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line > span::-moz-selection,
.cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-material .cm-keyword {
  color: #c792ea;
}

.cm-s-material .cm-operator {
  color: #89ddff;
}

.cm-s-material .cm-variable-2 {
  color: #eeffff;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #f07178;
}

.cm-s-material .cm-builtin {
  color: #ffcb6b;
}

.cm-s-material .cm-atom {
  color: #f78c6c;
}

.cm-s-material .cm-number {
  color: #ff5370;
}

.cm-s-material .cm-def {
  color: #82aaff;
}

.cm-s-material .cm-string {
  color: #c3e88d;
}

.cm-s-material .cm-string-2 {
  color: #f07178;
}

.cm-s-material .cm-comment {
  color: #546e7a;
}

.cm-s-material .cm-variable {
  color: #f07178;
}

.cm-s-material .cm-tag {
  color: #ff5370;
}

.cm-s-material .cm-meta {
  color: #ffcb6b;
}

.cm-s-material .cm-attribute {
  color: #c792ea;
}

.cm-s-material .cm-property {
  color: #c792ea;
}

.cm-s-material .cm-qualifier {
  color: #decb6b;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #decb6b;
}

.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

// regular css
.container {
  max-width: 100%;
  width: 1200px;
  margin: 0 auto;
}
.flex {
  display: flex;
  .h-spacer {
    flex: 1 1 auto;
  }
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.max100 {
  max-width: 100%;
}
.margin-auto {
  margin: auto;
}
.sml-btn {
  height: 30px;
  width: 30px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
}
form formly-field {
  legend {
    font-size: 14px;
  }
  legend:not(.object-head) {
    padding-top: 12px;
  }
  ion-select {
    width: 100%;
    max-width: 100%;
    font-size: 10px;
    --padding-start: 0;
  }
  ion-item {
    --min-height: 12px;
    --padding-start: 0;
    formly-field-ion-input ion-input.sc-ion-input-ios-h {
      font-size: 10px;
    }
  }
  ion-item.big-item {
    --padding-start: 18px;
    --min-height: 95px;
  }
}
form formly-field.checkbox {
  ion-label.sc-ion-label-ios-h {
    font-size: 14px;
  }
}
.big-item {
  --min-height: 95px;
}
.overflow-hidden {
  overflow: hidden;
}
.divider {
  height: 1px;
  background: rgba(var(--ion-color-dark-rgb), 0.25);
}
ion-chip.danger {
  --background: rgba(var(--ion-color-danger-rgb), 0.25);
  --color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
  ion-icon {
    color: var(--ion-color-danger);
  }
}
.smaller-icon {
  font-size: 0.9rem;
  font-weight: normal;
}
.tree-children {
  padding-left: 0;
}
.transparent {
  --background: transparent;
}
.s-text {
  font-size: 0.8rem;
}
.xs-text {
  font-size: 0.6rem;
}
ion-icon.flip {
  transform: rotate(180deg);
}
ion-alert {
  button.danger span.alert-button-inner {
    color: var(--ion-color-danger);
  }
}
.api-popover {
  --width: auto;
}
.light-bg {
  background: var(--ion-color-light);
}

.refresh-toast {
  font-size: 14px !important;
  --button-color: var(--ion-color-warning);
}
.toast-button {
  font-size: 14px !important;
}
.collection-list {
  ion-item {
    ion-label {
      font-size: 0.9rem;
    }
  }
}
